import { FC } from "react";
import { ReactComponent as NotificationsIcon } from "assets/icons/NotificationsIcon.svg";
import { Link } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import { useBreakpoint } from "@/hooks/useBreakpoint";
import clsx from "clsx";

type HeaderElementsAuthorizedDesktopProps = {
  name: {} | undefined;
  profile: Record<string, unknown>;
  notifications: number;
  onLogout: () => void;
  onHandleRequest: (path: string) => void;
};

export const HeaderElementsAuthorizedDesktop: FC<HeaderElementsAuthorizedDesktopProps> =
  ({ profile, name, notifications, onHandleRequest, onLogout }) => {
    const classNames = clsx({
      "xvia-header-btn": true,
      "xvia-header-btn__inactive": !!profile,
    });

    const isMobile = useBreakpoint();

    if (isMobile) {
      return null;
    }

    const menu = (
      <Menu>
        <Menu.Item
          onClick={() => onHandleRequest("/app/dashboard")}
          key="user-account"
        >
          Meus dados
        </Menu.Item>
        <Menu.Item onClick={onLogout} key="logout">
          Sair
        </Menu.Item>
      </Menu>
    );

    return (
      <div className={classNames}>
        <div className="xvia-header-user-actions">
          <Link className="xvia-header-user-notifications" to="/notifications">
            <NotificationsIcon className="xvia-header-user-notifications__icon" />
            {!!notifications && (
              <div className="xvia-header-user-notifications__unread">
                {notifications}
              </div>
            )}
          </Link>
          <Dropdown overlay={menu} placement="bottomRight">
            <button>
              {name || ""} <i className="far fa-user" />
            </button>
          </Dropdown>
        </div>
      </div>
    );
  };
